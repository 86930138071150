<template>
	<div class="article-box">
		<div class="big_box">
			<div class="">
				<span>异常类型</span>
				<el-select v-model="types" placeholder="请选择">
					<el-option v-for="(item,index) in machineDa" :key="index" :label="item.error_code"
						:value="item.error_code">
					</el-option>

				</el-select>
				&nbsp
				<!-- <span>注册人</span>
				<el-select v-model="register" placeholder="请选择">
					<el-option v-for="(item,index) in machineDa" :key="index" :label="item.account"
						:value="item.account">
					</el-option>
				</el-select> -->
				&nbsp
				<el-button @click="reset()">重置</el-button>
				<el-button type="primary" @click="classListDataSearchfirst(1)" round>搜索</el-button>


			</div>
			<div>
				<!-- <el-button @click="$router.go(-1)" type="primary" round>返回</el-button> -->
			</div>


		</div>
		<div class="blocks">
			<el-button round @click="prevDate(1)">昨天</el-button>
			<el-button round @click="prevDate(3)">3天</el-button>
			<el-button round @click="prevDate(7)">7天</el-button>
			<el-button round @click="prevDate(30)">30天</el-button>

			<!-- <el-date-picker style="margin-left: 5px;" v-model="value1" type="daterange" range-separator="~"
				start-placeholder="开始日期" end-placeholder="结束日期">
			</el-date-picker> -->
			<el-date-picker class="time" size="medium" type="daterange" v-model="machineData.picker"
				start-placeholder="开始日期" range-separator="-" end-placeholder="结束日期" :picker-options="pickerOptions"
				value-format="yyyy-MM-dd" unlink-panels @change="pickerChange">
			</el-date-picker>
		</div>

		<el-table :data="machineData" style="width: 100%" v-loading="loading">
			<!-- <el-table-column label="序号" type="index" align="center"></el-table-column> -->
			<el-table-column prop="id" label="序号" align="center" width="250"></el-table-column>
			<el-table-column prop="error_code" label="异常类型" align="center" width="250"></el-table-column>
			<el-table-column prop="ip" label="异常IP" align="center" width="200"></el-table-column>

			<el-table-column prop="content" label="内容" align="center" width="600">
				<!-- otype为1时是蓝牙为2是是有线 -->
				<!-- <template slot-scope="scope">
					<span>{{scope.row.register_info.osize}}寸{{scope.row.register_info.otype}}
						{{scope.row.register_info.is_face}}{{scope.row.register_info.board_type}}
						{{scope.row.register_info.remote_type}}{{scope.row.register_info.vision}}
						{{scope.row.register_info.link}}{{scope.row.register_info.is_red}}{{scope.row.register_info.amblyopia}}
						{{scope.row.register_info.stereoscope}}{{scope.row.register_info.purpose}}<strong></strong></span>

				</template> -->
			</el-table-column>
			<!-- <el-table-column prop="agent_name" label="注册信息" align="center"></el-table-column> -->
			<!-- <el-table-column prop="register_user" label="注册人" align="center" width="150"></el-table-column> -->

			<el-table-column prop="error_time" label="异常时间" align="center" width="150"></el-table-column>
			<!-- <el-table-column align="center" fixed="right" label="操作" width="200">
				<template slot-scope="scope">
					<el-button type="primary">同意</el-button>
					<el-button>拒绝</el-button>
				</template>
			</el-table-column> -->
			<!-- <el-table-column prop="provinceName" label="省" align="center"></el-table-column>
      <el-table-column prop="cityName" label="市" align="center"></el-table-column>
      <el-table-column prop="areaName" label="区" align="center"></el-table-column> -->
		</el-table>
		<div class="block">
			<el-pagination @current-change="handleCurrentChange" :page-size="pagesize" background
				layout="prev, pager, next, jumper" :total="CountNum" style="margin-top: 20px">
			</el-pagination>
		</div>
	</div>
</template>

<script>
	import {
		machineErrorType,
		machineErrorLogin
	} from '@/api/adminApi.js'
	export default {
		data() {
			return {
				selectDate: "",
				selectDate2: "",
				error_code:'',
				register: '', //注册人列表
				keyword: '', // 搜索关键字
				machineData: [], // 设备列表
				machineDatas: [], //设备类型
				machineDa: [],
				loading: false, //加载框
				currpage: 1, // 初始页
				pagesize: 10, // 每页显示条目个数
				CountNum: 0, // 总条数
				infoList: [],
				types: '', //类型
				id: '',
				value: '',
				value1: '',
				pickerOptions: {
					disabledDate(time) {
						return time.getTime() > Date.now();
					},
					shortcuts: [{
						text: '最近一周',
						onClick(picker) {
							const end = new Date();
							const start = new Date();
							start.setTime(start.getTime() - 3600 * 1000 * 24 * 7);
							picker.$emit('pick', [start, end]);
						}
					}, {
						text: '最近半个月',
						onClick(picker) {
							const end = new Date();
							const start = new Date();
							start.setTime(start.getTime() - 3600 * 1000 * 24 * 14);
							picker.$emit('pick', [start, end]);
						}
					}, {
						text: '最近一个月',
						onClick(picker) {
							const end = new Date();
							const start = new Date();
							start.setTime(start.getTime() - 3600 * 1000 * 24 * 30);
							picker.$emit('pick', [start, end]);
						}
					}]
				}

			}
		},
		mounted() {
			this.machineSerchList(1) // 获取第一页设备列表
			this.userList() //获取注册人
			this.formatted();
		},
		methods: {
			reset() { // 重置按钮
				this.register = ''
				this.types = ''
				this.currpage = 1
				this.machineSerchList(this.currpage)
			},
			//设备类型列表获取
			userList(currpage) {
				this.loading = true
				const data = {
					keyword: this.keyword,
					page: currpage, // 当前页数
					limit: this.pagesize ,// 每页条数
					error_code:this.error_code
				}
				machineErrorType(data).then(res => {
					this.machineDa = res.data || []
				}).catch(err => {
					console.log(err)
				})
			},
			formatted(currpage, row) {
				let formattedArray = this.machineData.picker?.map(dateString => {
					let dateObj = new Date(dateString);
					let year = dateObj.getFullYear();
					let month = dateObj.getMonth() + 1; // 月份从0开始，所以要加1
					let day = dateObj.getDate();

					month = month < 10 ? "0" + month : month;
					day = day < 10 ? "0" + day : day;
					let formattedDate = "" + year + month + day;
					this.selectDate = formattedDate;
					this.selectDate2 = formattedDate;
					return formattedDate;
				})
				console.log(formattedArray)
				this.getData(1, formattedArray)
			},

			getData(currpage, row) {
				console.log(row)
				this.loading = true;
				const data = {
					page: currpage, // 当前页数
					limit: this.pagesize, // 每页条数
					error_date_start: row[0], // 开始时间
					error_date_end: row[1], // 结束时间
				}
				machineErrorLogin(data).then(res => {
					this.CountNum = parseInt(res.data.countNum); // 总条数
					this.machineData = res.data.list;
					this.currpage = currpage; //分页显示当前页
				}).catch(err => {
					console.log(err)
				}); //请求接口

				this.loading = false;
			},
			//昨天
			prevDate(index) {
				console.log(index)
				var now = new Date();
				var yesterday = new Date(now);
				var formattedDate1 = now.getFullYear() + '' + this.padZero(now.getMonth() + 1) + '' + this.padZero(now
					.getDate());
				if (index === 1) {
					yesterday.setDate(now.getDate() - 1);
					var formattedDate = yesterday.getFullYear() + '' + this.padZero(yesterday.getMonth() + 1) + '' + this
						.padZero(yesterday.getDate()); //昨天的开始时间
					var aaa = []
					aaa.push(formattedDate, formattedDate1)
					this.getData(1, aaa)
				}
				if (index === 3) {
					var threeDaysAgo = new Date(now);
					threeDaysAgo.setDate(now.getDate() - 3);
					var formattedDate3 = threeDaysAgo.getFullYear() + '' + this.padZero(threeDaysAgo.getMonth() + 1) + '' +
						this.padZero(threeDaysAgo.getDate());
					var bbb = []
					bbb.push(formattedDate3, formattedDate1)
					this.getData(1, bbb)
				}
				if (index === 7) {
					var sevenDaysAgo = new Date(now.getTime() - (7 * 24 * 60 * 60 * 1000));
					var formattedDate7 = sevenDaysAgo.getFullYear() + '' + this.padZero(sevenDaysAgo.getMonth() + 1) + '' +
						this.padZero(sevenDaysAgo.getDate());
					var ccc = []
					ccc.push(formattedDate7, formattedDate1)
					this.getData(1, ccc)
				}
				if (index === 30) {
					var threesDaysAgo = new Date(now.getTime() - (30 * 24 * 60 * 60 * 1000));
					var formattedDate30 = threesDaysAgo.getFullYear() + '' + this.padZero(threesDaysAgo.getMonth() + 1) +
						'' + this.padZero(threesDaysAgo.getDate());
					var ddd = []
					ddd.push(formattedDate30, formattedDate1)
					this.getData(1, ddd)
				}
			},
			//时间补零
			padZero(num) {
				return (num < 10 ? '0' : '') + num;
			},
			pickerChange(e) { //选择日期
				this.formatted(1, this.machineData.register_date); // 根据选取的日期获取班级出入列表数据
			},
			classListDataSearchfirst(val) { // 只有当点击查询时候走这个方法
				const data = {
					page: val,
					limit: this.pagesize,
					error_code: this.types,
				}
				machineErrorLogin(data).then(res => {
					this.currpage = 1
					this.machineData = res.data.list
					this.CountNum = parseInt(res.data.countNum)
					this.loading = false
				}).catch(err => {
					console.log(err)
				})

			},
			machineSerchList(currpage) { // 设备列表
				this.loading = true
				const data = {
					keyword: this.keyword,
					page: currpage, // 当前页数
					limit: this.pagesize // 每页条数
				}
				machineErrorLogin(data).then(res => {
					this.machineData = res.data.list // 设备列表		
					this.CountNum = parseInt(res.data.countNum) // 总条数
					this.loading = false
				}).catch(err => {
					console.log(err)
				})
			},
			handleCurrentChange(val) { // 分页
				this.machineSerchList(val)
				this.currpage = val
			},

		}
	}
</script>

<style lang="scss" scoped>
	.big_box {
		width: 100%;
		display: flex;
		justify-content: space-between;

		.box {
			display: flex;


		}

		.col {

			width: 100%;
			display: flex;
			justify-content: flex-end;

			.btn {
				width: 9.5625rem;
				height: 3.125rem;
				background-color: #003882;
				font-size: 1.25rem;
			}
		}
	}

	.el_buu {
		font-size: 16px;
		color: #00BFBF;
	}

	.blocks {
		display: flex;
	}
</style>
